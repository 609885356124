module.exports={
"label.account.name":`Nombre`,
"label.account.copy":`Por favor, copie el código de verificación aleatorio en el correo electrónico, y haga clic en el enlace para cambiar la contraseña en el siguiente paso.`,
"label.account.phone.length":`El número de móvil requiere al menos 7 caracteres`,
"label.account.Start.trial":`OBTENER UNA RESPUESTA`,
"label.account.employees1000":`Más de 1000 empleados`,
"label.account.other.Ways":`Otros métodos de acceso`,
"label.account.emailsync.button.cancel":`Cancelar`,
"label.account.rule":`Consta de 8 a 16 dígitos y letras.`,
"label.account.password.update.success":`La contraseña ha sido cambiada con éxito.`,
"label.account.describe":`CloudCC se centra en la personalización digital, la inteligencia analítica y la conectividad global, y se personaliza bajo demanda junto con el desarrollo del negocio empresarial.`,
"label.account.enter.Emailbox":`Por favor, introduzca su dirección de correo electrónico.`,
"label.account.password":`Contraseña`,
"label.account.problem":`El inicio de sesión ha fallado.`,
"label.account.Complete.reset":`Correo electrónico. Por favor, introduzca el código de verificación para completar el restablecimiento de la contraseña.`,
"label.account.crm.used":`CRM utilizado por las empresas que cotizan en bolsa`,
"label.account.report.loading":``,
"label.account.notcustomer":`¿No tiene cuenta?`,
"label.account.employees501":`5011000 empleados`,
"label.account.title.operationManager":`Director de Operaciones`,
"label.account.Preset":`Herramientas de gestión de proyectos preestablecidas`,
"label.account.company":`Empresa`,
"label.account.come":`Bienvenido a la plataforma de éxito del cliente de Cloudcc. Nuestra nueva plataforma relámpago le proporciona la solución más rápida y completa, permitiéndole hacer todo con su cliente en el centro.  `,
"label.account.input.password":`Por favor, introduzca su contraseña.`,
"label.account.emailobject.emaildetail.button.back":`Volver`,
"label.account.title.itManager":`Gerente de TI`,
"label.account.Modified.successfully":`El cambio de contraseña se ha realizado con éxito. Por favor, haga clic en iniciar sesión de nuevo para volver a la página de inicio de sesión.`,
"label.account.only11":`Por favor, introduzca al menos 7 dígitos.`,
"label.account.signin":`Inicio de sesión`,
"label.account.register":`Regístrese para obtener`,
"label.account.adopt":`¡Verificación superada!`,
"label.account.title.hrManager":`Director de marketing/de recursos humanos`,
"label.account.input.usernames":`Por favor, introduzca su nombre de usuario.`,
"label.account.only.Numbers":`El número de teléfono sólo puede introducir números.`,
"label.account.title.salesManager":`Director de ventas`,
"label.account.email":`Envíe un correo electrónico a`,
"label.account.click":`Haga clic en`,
"label.account.and":`y`,
"label.account.mobile":`Teléfono`,
"label.account.Privacy.policy":`Política de privacidad`,
"label.account.password.isnull":`La contraseña no puede estar vacía.`,
"label.account.employees101":`101500 empleados`,
"label.account.remember.name":`Recuerda el nombre de usuario`,
"label.account.Select.country":`Seleccione su país/región.`,
"label.account.distinguish":`La contraseña distingue entre mayúsculas y minúsculas.`,
"label.account.fillMobile":`Por favor, introduzca su número de teléfono.`,
"label.account.Preconfigured":`Informes y cuadros de mando preestablecidos`,
"label.account.Select.job":`Por favor, seleccione su posición.`,
"label.account.employees21":`21100 empleados`,
"label.account.username":`Nombre de usuario`,
"label.account.Submit":`Enviar`,
"label.account.confirm":`Confirmación de la cuenta`,
"label.account.contact":`Contacto`,
"label.account.country":`País`,
"label.account.username.isnull":`El nombre de usuario no puede estar vacío.`,
"label.account.forget.password":`¿Ha olvidado su contraseña?`,
"label.account.Loading":`Cargando`,
"label.account.goon":`Continuar`,
"label.account.Register.trial":`Regístrese para una prueba gratuita`,
"label.account.title.other":`Otros`,
"label.account.surname":`Apellido`,
"label.account.Fail":`Verificación fallida, por favor, inténtelo de nuevo.`,
"label.account.Terms.service":`Condiciones de servicio`,
"label.account.Select.staff":`Seleccione el número de empleados.`,
"label.account.username.enter":`Por favor, introduzca su apellido.`,
"label.account.trial":`Prueba gratuita`,
"label.account.employees1":`120 empleados`,
"label.account.Quick.registration":`o acceder rápidamente`,
"label.account.tabpage.ok":`Confirmar`,
"label.account.normal.reregister":`Vuelva a iniciar sesión`,
"label.account.batchadd.newbatchpage.save.em":`El formato del correo electrónico es incorrecto.`,
"label.account..user.title":`Cargo`,
"label.account.No.credit.card.required":`No se requiere tarjeta de crédito.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Precarga de datos de la muestra`,
"label.account.title.hobbies":`Aficiones`,
"label.account.platform":`Nueva plataforma CRM inteligente, móvil y digital`,
"label.account.sent":`Correo electrónico enviado`,
"label.account.Confirm.password":`Por favor, confirme su contraseña.`,
"label.account.fill":`Por favor, rellene todo.`,
"label.account.user.resetpw":`Restablecer contraseña`,
"label.account.Spell":`Arrastra el deslizador para completar el puzzle.`,
"label.account.identifying.code":`Por favor, introduzca el código de verificación.`,
"label.account.staff":`Empleados`,
"label.account.welcome":`Bienvenido a CloudCC`,
"label.account.online.guidance":`Asesoramiento e instrucción en línea`,
"label.account.login.CloudCC":`Acceder a CloudCC`,
"label.account.Company.name":`Por favor, introduzca el nombre de su empresa.`,
"label.account.title.generalManager":`Director General`,
"label.account.enter.user":`Para restablecer su contraseña, introduzca su nombre de usuario de CloudCC.`,
"label.account.sent.code":`El código de verificación ha sido enviado a`,
"label.account.pw.error.text":`Las dos contraseñas introducidas no coinciden.`,
"label.account.title.customerManager":`Responsable del servicio de atención al cliente`,
"label.account.label.captcha":`Código de verificación`,
"label.account.Trial.days":`Regístrese para una prueba gratuita de 30 días`,
"label.account.please.enter.name":`Por favor, introduzca su nombre.`,
"label.account.inspect":`Si no recibe el correo electrónico de confirmación del código de verificación, compruebe el correo no deseado.`,
"label.account.User.rule":`El nombre de usuario tiene la forma de una dirección de correo electrónico.`,
"label.account.go.Login":`¿Ya tiene una cuenta?`,
"label.account.Reading.consent":`Estoy de acuerdo con el`,
"label.account.Change.Password2":`Cambiar la contraseña`,
"label.account.Loginby":`Inicio de sesiónpor`,
"label.account.apply.partner":`Solicite ser socio de CloudCC`,
"label.account.MobileLogin":`Móviles`,
"label.account.Registration":``,
"label.account.Italy":`Italia`,
"label.account.view.details":`Ver detalle`,
"label.account.accept":`Lea y acepte las condiciones de servicio y la política de privacidad`,
"label.account.China.Mainland":`China continental`,
"label.account.NewPassword":`Por favor, introduzca la nueva contraseña`,
"label.account.Mobile.not.empty":`El número de móvil no puede estar vacío`,
"label.account.Please.select":`Por favor, elija`,
"label.account.market.introduce":`La plataforma unificada de canales de marketing le ayuda a ganar más clientes potenciales`,
"label.account.IOS":`Escanear para descargar la aplicación iOS`,
"label.account.failsend":`Fallo en el envío`,
"label.account.UpperLowerlAphanumeric.character":`Se requieren letras mayúsculas y minúsculas, números y símbolos`,
"label.account.New.Password1":`Nueva contraseña`,
"label.account.succSend":`Se le ha enviado un correo electrónico. Por favor, copie el código de verificación en el correo electrónico y restablezca la contraseña.`,
"label.account.Account.mobile":`Número de cuenta o de móvil`,
"label.account.Login.consent":`Al iniciar sesión, usted acepta`,
"label.account.fillAreaCode":`Por favor, introduzca el código de área`,
"label.account.active.login":``,
"label.account.jp":`Japón`,
"label.account.partner.success1":`Se ha registrado correctamente como socio de CloudCC.`,
"label.account.Reset":`Reiniciar`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Por favor, introduzca la antigua contraseña`,
"label.account.Germany":`Alemania`,
"label.account.title.content":`Regístrese en CloudCC | 30 días de prueba gratuita  ¡Pruébelo ahora!`,
"label.account.Taiwan":`Taiwán, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`La automatización de las ventas le ayuda a conseguir más acuerdos`,
"label.account.Back":`Paso anterior`,
"label.account.market":`Nube de marketing`,
"label.account.section.company":`Detalles de la empresa`,
"label.account.check.email":`nuestro ejecutivo de cuentas se pondrá en contacto con usted dentro de un rato, por favor revise su correo electrónico a tiempo`,
"label.account.characters":`5 caracteres`,
"label.account.International":`Internacional`,
"label.account.britain":`Reino Unido`,
"label.account.reg.fail.msg":`El registro ha fallado. Por favor, inténtelo más tarde.`,
"label.account.success.reg":`se ha registrado con éxito`,
"label.account.company.url":`Página web`,
"label.account.CannotBeEmpty":`No puede estar vacío`,
"label.account.phone.note":``,
"label.account.second":`{0} segundos después`,
"label.account.Change.Password":`Cambiar la contraseña`,
"label.account.Netherlands":`Países Bajos`,
"label.account.pleaseAgree":`Por favor, acepte y esté de acuerdo con las condiciones de servicio y la política de privacidad`,
"label.account.copyright":`CloudCC Inc. Todos los derechos reservados`,
"label.account.letter":`1 carta`,
"label.account.remember":`Ahora recuerdo`,
"label.account.accountNum":`Cuenta`,
"label.account.code.not.empty":`El captcha no puede estar vacío`,
"label.account.Spain":`España`,
"label.account.Alphanumeric":`Se requiere una mezcla de letras y números`,
"label.account.obtain":`Adquirir`,
"label.account.company.txt":`Por favor, añada su nombre, si no tiene una entidad comercial registrada`,
"label.account.con.browser":``,
"label.account.Android":`Escanear para descargar la aplicación Android`,
"label.account.title":`Prueba gratuita de CloudCC  CloudCC.com`,
"label.account.partner.success2":`Nuestro se pondrá en contacto con usted en un tiempo.`,
"label.account.Macao":`Macao, China`,
"label.account.Brazil":`Brasil`,
"label.account.service.introduce":`El proceso de servicio sin fisuras mejora la eficacia de la respuesta`,
"label.account.codeFailure":`Código de verificación inválido`,
"label.account.AccountLogin":`Cuenta`,
"label.account.pswComplexReqRules":`La longitud mínima de la contraseña es {0}, y {1}`,
"label.account.UpperLowerlAphanumeric":`Se requieren letras mayúsculas y minúsculas, y números`,
"label.account.active.username":``,
"label.account.rememberPassword":`Recordar contraseña`,
"label.account.HongKong":`Hon Kong, China`,
"label.account.oldPass":`Contraseña antigua`,
"label.account.Data.storage":`Sus datos se almacenarán en el centro de datos de {0}.`,
"label.account.number":`1 número`,
"label.account.Poland":`Polonia`,
"label.account.service":`Nube de servicios`,
"label.account.Incorrect.format":`Formato o número incorrecto, vuelva a introducirlo`,
"label.account.remember.status":`Acuérdate de mí`,
"label.account.sale":`Nube de ventas`,
"label.account.No.account.binding":`Ninguna cuenta está vinculada. Por favor, póngase en contacto con el administrador`,
"label.account.Russia":`Rusia`,
"label.account.France":`Francia`,
"label.account.partner.content":`CloudCC es una empresa global que ahora está reclutando socios para explorar soluciones en todas las industrias. ¡Venga y únase a nuestro programa de afiliados!`,
"label.account.reg.phone.already":`Esta organización ya existe para este teléfono. Inicia sesión o utiliza otro teléfono para registrarte.`,
"label.account.Confirm":`Confirmar nueva contraseña`,
"label.account.VerificationLogin":`Verificar el código`,
"label.account.USA":`Estados Unidos`,
"label.account.mobile.num":`Por favor, introduzca el número de móvil`,
"label.account.MinLength":`La longitud mínima de la contraseña es`,
"label.account.change.your.password":`Por favor, cambie la contraseña ahora en vista de la seguridad`,
"label.account.new.password":`Introduzca una nueva contraseña para {0}. Asegúrese de incluir al menos:`,
"label.account.website.enter":`Introduzca la dirección del sitio web oficial`,
"label.account.pswComplexIsZero":``
}